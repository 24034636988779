import { CapacitorFirebaseDynamicLinksPlugin } from "@clipboardhealth/capacitor-firebase-dynamic-links";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { USER_EVENTS } from "@src/constants";
import { getHumanReadableTag } from "@src/lib/utils";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getMessageRecord } from "./api";
import { generateDynamicLinkListener } from "./listener";
import { useOpenShiftContext } from "../openShifts/context";
import { recordImpression } from "../pushNotifications/api";
import { useSession } from "../store/helperHooks";

export function useDynamicLinkHandler() {
  const { isAuthorized } = useSession();

  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });
  const { userId } = worker ?? {};

  const updateWorkerCache = useUpdateWorkerCache();

  const dispatch = useDispatch();
  const history = useHistory();
  const { setSegmentView, setShiftBookingType } = useOpenShiftContext();

  const [url, setUrl] = useState("");
  const [unconsumedLink, setUnconsumedLink] = useState("");
  /**
   * this will push log the specific message to the segment before pushing it to the view
   */
  const pushToLink = useCallback(async () => {
    if (!userId || !url) {
      return;
    }
    const urlParsed = new URL(url);
    const methodValue = urlParsed.searchParams.get("method");
    const messageId = urlParsed.searchParams.get("messageId") as string;
    recordImpression(messageId, "sms");

    const userPhone = urlParsed.searchParams.get("phoneNumber");
    const preferenceType = urlParsed.searchParams.get("preferenceType");
    if (!messageId) {
      return;
    }
    try {
      const response = await getMessageRecord(messageId, "sms");
      if (response.code === 0) {
        logEvent(USER_EVENTS.MESSAGE_OPENED, {
          by: "Worker",
          message_name: getHumanReadableTag(methodValue ?? ""),
          message: response.data?.message ?? "",
          method: methodValue,
          channel: "sms",
          channelType: preferenceType,
          notifiyId: messageId,
          userPhone,
        });
      }
    } catch (ex) {
      // Ignore
    }
  }, [url, userId]);
  useEffect(() => {
    if (!isCapacitorPlatform()) {
      return;
    }
    const capacitorDeepLinkListener = CapacitorFirebaseDynamicLinksPlugin.addListener(
      "deepLinkOpen",
      generateDynamicLinkListener(
        worker,
        isAuthorized,
        setUrl,
        pushToLink,
        history,
        dispatch,
        updateWorkerCache,
        setUnconsumedLink,
        setSegmentView,
        setShiftBookingType
      )
    );
    return () => {
      capacitorDeepLinkListener.then((capacitorDeepLinkListenerHandle) =>
        capacitorDeepLinkListenerHandle.remove()
      );
    };
  }, [
    worker,
    history,
    pushToLink,
    isAuthorized,
    dispatch,
    setSegmentView,
    setShiftBookingType,
    updateWorkerCache,
  ]);

  useEffect(() => {
    pushToLink();
  }, [pushToLink]);

  useEffect(() => {
    // This will be called when the deep link listener sets the unconsumedLink as the user was not logged in.
    // once they login manually or the auto login occurs, isAuthorized & userId will be set and this useEffect will be triggered.
    // We set the unconsumedLink to null once it has been consumed.

    // PS: When the app is not running in the background and a deep link is clicked, up until the user's session is authorized,
    // they are considered to be logged out and we set the unconsumedLink

    if (unconsumedLink && isAuthorized && userId) {
      generateDynamicLinkListener(
        worker,
        isAuthorized,
        setUrl,
        pushToLink,
        history,
        dispatch,
        updateWorkerCache,
        setUnconsumedLink,
        setSegmentView,
        setShiftBookingType
      )({ url: unconsumedLink });
      setUnconsumedLink("");
    }
  }, [
    userId,
    isAuthorized,
    unconsumedLink,
    worker,
    setSegmentView,
    pushToLink,
    history,
    dispatch,
    setShiftBookingType,
    updateWorkerCache,
  ]);
}
